import React from 'react';
import PropTypes from 'prop-types';
import { showReportDialog, ErrorBoundary } from '@sentry/gatsby';
import { Button, Container } from '@pella/sash-framework';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { getSessionStorage } from '../../utilities/functions';
import Logo from '../../common/logo';

function FallbackComponent() {
	const csData = useStaticQuery(query).contentstackGlobalConfig;
	return (
		<div
			className='flex h-screen items-center overflow-auto bg-gray-5 py-14'
			id='error-boundary-fallback'
		>
			<Container
				width={600}
				className='m-auto'
			>
				<div className='bg-white p-14 pt-8 text-center shadow-lg'>
					<Link
						href={csData.error_handling.cta[0].destination[0].destination}
						title={csData.error_handling.cta[0].cta_title}
						className='flex justify-center'
					>
						<Logo className='relative z-10 mb-2 block h-24 max-w-full transition-all duration-300' />
					</Link>
					<h1 className='text-3xl'>{csData.error_handling.heading}</h1>
					<p>{csData.error_handling.description}</p>
					<Button
						className='my-6 !w-full'
						onClick={() => {
							showReportDialog({ eventId: event.event_id });
						}}
					>
						{csData.error_handling.cta_title}
					</Button>
					<Link
						href={csData.error_handling.cta[0].destination[0].destination}
						title={csData.error_handling.cta[0].cta_title}
					>
						{csData.error_handling.cta[0].cta_title}
					</Link>
				</div>
			</Container>
		</div>
	);
}

const ErrorBoundaryComp = (props) => {
	const sendCaptureData = (scope, error) => {
		if (error instanceof RangeError) {
			const redux = getSessionStorage('reduxState');
			scope.setContext({
				firstTimeslot: redux?.rtaData?.Timeslots?.[0]?.START_DATE,
				lastTimeslot: redux?.rtaData?.Timeslots?.at(-1)?.START_DATE,
				currentDate: new Date().toISOString(),
			});
		}
	};

	return (
		<ErrorBoundary
			fallback={<FallbackComponent />}
			beforeCapture={(scope, error) => sendCaptureData(scope, error)}
		>
			{props.children}
		</ErrorBoundary>
	);
};

ErrorBoundaryComp.propTypes = {
	children: PropTypes.node,
};

export default ErrorBoundaryComp;

export const query = graphql`
	query {
		contentstackGlobalConfig {
			error_handling {
				heading
				description
				cta_title
				cta {
					cta_title
					destination {
						destination
					}
				}
			}
		}
	}
`;
