/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./node_modules/@pella/sash-framework/dist/index.css');

import { getSessionStorage, setSessionStorage, removeSessionStorage } from './src/components/utilities/functions';
import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;

// Anchor Function for initial render of page with anchor
let prev;

export const onRouteUpdate = ({ location, prevLocation }) => {
	// Tracking previous page for article offramps
	if (prevLocation?.pathname && prevLocation.pathname !== location.pathname) {
		setSessionStorage('previousPage', {
			prevUrl: prevLocation.pathname,
			branchReferrer: prevLocation?.pathname?.includes('/locations/') ? true : false,
			productType: prevLocation?.pathname?.includes('/windows/') ? 'Window' : prevLocation?.pathname?.includes('/patio-doors/') ? 'Patio Door' : prevLocation?.pathname?.includes('/entry-doors/') ? 'Entry Door' : '',
		});
	}

	// remove sessionStorage when landing on RTA confirmation, or if landing on a page that is not branch/RTA specifically
	if (getSessionStorage('branchID') && (location.pathname === '/where-to-buy/schedule-consultation/confirmation/' || (location.pathname !== '/where-to-buy/schedule-consultation/' && !location.pathname.includes('/locations/')))) {
		removeSessionStorage('branchID');
	}

	const startsWith = /^[a-zA-Z]/i;
	const specialCharacters = /^[a-zA-Z0-9-_ ]*$/;
	if (location.hash && location.hash !== '' && startsWith.test(location.hash.substring(1)) && specialCharacters.test(location.hash.substring(1))) {
		// If there is an anchor, scroll to that anchor
		setTimeout(() => {
			if (document.querySelector(`${location.hash}`)) {
				document.querySelector(`${location.hash}`)?.scrollIntoView();
				document.querySelector(`${location.hash}`)?.focus();
			}
		}, 100);
	} else if (prev !== location.pathname) {
		// Watch previous location to check for back button click so scroll position remains
		prev = prevLocation?.pathname;

		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'instant' });
		}, 0);
	}

	// close mobile menu on same page click
	if (window.innerWidth <= 1024 && window.location.href === location.href) {
		const close = document.getElementById('mobileMenu-close');
		if (close && close.getAttribute('aria-expanded') === 'true') close.click();
	}

	const canonicalTag = document.querySelector('link[rel="canonical"]');

	if (canonicalTag) {
		canonicalTag.setAttribute('href', `${window.location.origin}${location.pathname}`);
	}
};
